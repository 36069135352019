import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {URLs} from './variablesDebug.service';

var baseUrl;



@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient, private urls: URLs,private router: Router) {
    urls=new URLs();
    if (urls.debug==true){
        baseUrl=urls.debugUrl;
    }
    else{
        baseUrl=urls.devUrl;
    }
  }

  login(username: string, password: string) {
    const data = {
      client_id: 'CwI9ITX06j18NjEOu258SsvBWBXBNT5eFkiEDCXl',
      client_secret:
        'gFMatk4KKu1XeqHkDULHEtF2nuBnLqqWxgXTK3xIrfvgT5XJWrBOFlEVXNzATy4MP5jU4mfBkrBWJ8JcEhQ0nJH5tUueImeOnp6uc6QoRq5tDHc1P8C39rxUvMPRuQ8L',
      grant_type: 'password',
      username: username,
      password: password,
    };

    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append(
      'content-type',
      'application/x-www-form-urlencoded'
    );
    headers = headers.append('Accept', '*/*');
    headers = headers.append('Access-Control-Allow-Origin', '*');

    return new Promise((resolve) => {
      this.http
        .post<any>(baseUrl+'oauth/token/', data, { headers: headers })
        .pipe(
          map((user) => {
            if (user && user.access_token) {
              user['usuario']=username;
              localStorage.setItem('userIni', JSON.stringify(user));
            }
            return user;
          })
        )
        .subscribe(
          (data) => {
            // console.log(data)
            resolve(data);
          },
          (error) => {
            console.log(error);
            alert("Credenciales incorrectas")
          }
        );
    });
  }

  logout() {
    if (localStorage.getItem('userIni')) {
      localStorage.removeItem('userIni');
    }
  }

  getGroup(){
     if (localStorage.getItem('userIni')) {
        let headers = new HttpHeaders();
        headers = headers.append(
            'Authorization',
            `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`
        );

        let params = new HttpParams();
        return new Promise((resolve) => {
            this.http.get(baseUrl+'api/groups', { headers, params }).subscribe(
                (data) => {
                    resolve(data);
                },
                (error) => {
                    resolve(error);
                    console.log(error);
                }
            );
        });
    }
    return null;
  }
}
