import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { ChildActivationStart, Router } from  "@angular/router";

@Component({
  selector: 'app-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.css']
})
export class AdministradorComponent implements OnInit {

  constructor(private apiLogin:LoginService, private router:Router) { }

  panelColaboradores = true;
  panelExpedientes = false;
  panelReportes = false;
  panelFiscalizador = false;

  ngOnInit(): void {
    this.checkLogin();
  }


  checkLogin(){
    this.apiLogin.getGroup().then(result=>{
      if (result['grupo']!='Admin'){
        this.router.navigate(['/login/']);
      }
    });
  }

  gestionColaboradores(){
    this.panelColaboradores = true;
    this.panelExpedientes = false;
    this.panelReportes = false;
    this.panelFiscalizador = false;
  }

  asignarFiscalizacion(){
    this.panelColaboradores = false;
    this.panelExpedientes = false;
    this.panelReportes = false;
    this.panelFiscalizador = true;
  }

  reportes(){
    this.panelColaboradores = false;
    this.panelExpedientes = false;
    this.panelReportes = true;
    this.panelFiscalizador = false;
  }

  borrarExpediente(){
    this.panelColaboradores = false;
    this.panelExpedientes = true;
    this.panelReportes = false;
    this.panelFiscalizador = false;
  }

}
