<div class="content-fluid">
    <div class="row">
      <div class="col">
        <div id="generalComponent">
  
          <div class="row">
            <!-- Lado izquierdo -->
   
            <div class="col">
              <br>
              <h1 class="h4 font-weight:800">Buscar expediente</h1>
              <hr>
              
                <div class="row mb-3">
                  <div class="col">
  
                    <label for="junta">Junta</label>
                    <select class="form-control" id="junta" disabled="true" >
  
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="expediente">Expediente</label>
                    <input type="text" ng-pattern="/^-?[0-9][^\.]*$" onKeyUp="if(this.value<=0){this.value='';}"
                      id="expediente" class="form-control" placeholder="Expediente" disabled="true" >
  
                  </div>
                  <div class="col">
                    <label for="anio">Año</label>
                    <input type="text" 
                  id="anio" class="form-control" placeholder="año" disabled="true" >
                  </div>
                </div>
                
                <br>
                <br>
                <div>
                  
  
  
  
                  <button class="btn-seccion" (click)="demandaDisplay()"><span><label
                        class="h4 font-weight-normal">Demanda</label><span class="rounded float-end"
                        *ngIf="seccionDemanda"><i class="bi bi-chevron-double-up"></i></span><span
                        class="rounded float-end" *ngIf="!seccionDemanda"><i
                          class="bi bi-chevron-double-down"></i></span></span></button>
                  <hr>
                  <div id="seccionDemanda">
                    <form [formGroup]="form2">
                    <h3>Datos generales de la demanda</h3>
                    <div class="row">
                      <input class="btn btn-sm btn-green btn-block" type="reset" value="Limpiar datos de la demanda"
                        >
                      <br>
                      <div class="col">
                        <label for="fechaDemanda">Fecha de la demanda:</label>
                        <input class="form-control" type="date" id="fechaAuto"  formControlName="fechaD">
                        <div class="error-msg"
                          *ngIf="((this.form2.get('fechaD').invalid && buttonBoolDem && !demandaExistente  ) || (this.form2.get('fechaD').invalid && this.form2.get('fechaD').touched))">
                          Fecha de la demanda requerida
                        </div>
  
                        <label for="numAct">Número de demandados</label>
                        <input type="number" class="form-control" id="numDemandados" placeholder="Número de demandados"
                          formControlName="numDemandados">
                        <div class="error-msg"
                          *ngIf="((this.form2.get('numDemandados').invalid && buttonBoolDem && !demandaExistente  ) || (this.form2.get('numDemandados').invalid && this.form2.get('numDemandados').touched))">
                          Número de demandados requerido
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <br>
                        <label for="Booleans"><b>Marcar los siguientes puntos en caso de ser necesario</b></label>
                        <div class="form-check">
  
                          <input class="form-check-input" type="checkbox" value="" id="codemanda">
                          <label class="form-check-label" for="codemanda">
                            Codemanda SARS/IMSS/INFONAVIT
                          </label>
                        </div>
                        <div class="form-check">
  
                          <input class="form-check-input" type="checkbox" value="" id="prevencion">
                          <label class="form-check-label" for="prevencion">
                            Prevención
                          </label>
                        </div>
                        <div class="form-check">
  
                          <input class="form-check-input" type="checkbox" value="" id="reclutamiento">
                          <label class="form-check-label" for="reclutamiento">
                            Reclutamiento
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <label for="Booleans"><b>Información de los abogados:</b></label>
                      <div class="col-md-6 col-lg-6">
  
  
                        <label for="tipoAbogado">Tipo de abogado</label>
                        <select class="form-control" id="tipoAbogado" (change)="changeForTipoAbogado()"
                          formControlName="tipoA">
                          <option [value]="1">Privado</option>
                          <option [value]="2">Sindicato</option>
                          <option [value]="3">Procurador</option>
                        </select>
                        <div class="error-msg"
                          *ngIf="((this.form2.get('tipoA').invalid && buttonBoolDem && !demandaExistente) || (this.form2.get('tipoA').invalid && this.form2.get('tipoA').touched))">
                          Especificar el tipo de abogado
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <label for="despacho">Despacho</label>
                        <input type="text" class="form-control" id="despacho" placeholder="Despacho">
                      </div>
                      <div class="error-msg" *ngIf="!boolDespacho">
                        Nombre del despacho o no especifica
                      </div>
  
                      <div class="error-msg"
                        *ngIf="((this.form2.get('tipoA').invalid && buttonBoolDem && !demandaExistente) || (this.form2.get('tipoA').invalid && this.form2.get('tipoA').touched ))">
                        Nombre completo del abogado o no especifica
                      </div>
                    </div>
  
                    <div class="row">
                      <div class="col">
  
                        <label for="abogado1">Nombre completo del primer abogado:</label>
                        <input class="form-control" placeholder="Nombre completo" formControlName="nomAb1" id="abogado1">
                        <div class="error-msg"
                          *ngIf="((this.form2.get('nomAb1').invalid && buttonBoolDem && !demandaExistente) || (this.form2.get('nomAb1').invalid && this.form2.get('nomAb1').touched))">
                          Nombre completo del abogado o no especifica
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
  
                        <label for="abogado2">Nombre completo del segundo abogado:</label>
                        <input class="form-control" id="abogado2" placeholder="Nombre completo" formControlName="nomAb2">
                        <div class="error-msg"
                          *ngIf="((this.form2.get('nomAb2').invalid && buttonBoolDem && !demandaExistente) || (this.form2.get('nomAb2').invalid && this.form2.get('nomAb2').touched ))">
                          Nombre completo del abogado o no especifica
                        </div>
                      </div>
                    </div>
  
                    <div class="row">
                      <div class="col">
  
                        <label for="abogado3">Nombre completo del tercer abogado:</label>
                        <input class="form-control" id="abogado3" placeholder="Nombre completo" formControlName="nomAb3">
                        <div class="error-msg"
                          *ngIf="((this.form2.get('nomAb3').invalid && buttonBoolDem && !demandaExistente) || (this.form2.get('nomAb3').invalid && this.form2.get('nomAb3').touched))">
                          Nombre completo del abogado o no especifica
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <label for="Booleans"><b>Información de la empresa:</b></label>
                      <div class="col-md-8 col-lg-8">
                        <label for="giroE">Giro de la empresa</label>
                        <input type="number" class="form-control" id="giro" placeholder="Giro de la empresa"
                          formControlName="giro">
                        <div class="error-msg"
                          *ngIf="((this.form2.get('giro').invalid && buttonBoolDem && !demandaExistente) || (this.form2.get('giro').invalid && this.form2.get('giro').touched ))">
                          giro requerido, consultar el catálogo
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4">
                        <a href="https://www.inegi.org.mx/app/scian/" target="_blank">Consulta el catálogo</a>
                      </div>
  
  
                    </div>
                    <br>
                    <br>
                    <div class="row">
                      <div class="col"><button class="btn btn-greenL" type="button" (click)="demandaDatos()">Guardar
                          cambios de la demanda</button></div>
  
  
                    </div>
  
  
                    <br>
                    <br>
                  </form>
                  </div>
  
  
  
                </div>
              
  
  
  
  
              <button class="btn-seccion" (click)="actoresDisplay()"><span><label
                    class="h4 font-weight-normal">Trabajadores</label><span class="rounded float-end"
                    *ngIf="seccionActores"><i class="bi bi-chevron-double-up"></i></span><span class="rounded float-end"
                    *ngIf="!seccionActores"><i class="bi bi-chevron-double-down"></i></span></span></button>
              <hr>
              <div id="seccionActores">
                <div class="row mb-3">
                  <div class="col-6">
                    <h3>Trabajadores</h3>
                  </div>
                  <div class="col-3">
  
                  </div>
  
                </div>
                <form [formGroup]="form">
                  <input class="btn btn-sm btn-green btn-block" type="reset" value="Limpiar datos del trabajador" (click)="borrar()"
                    >
                  <br>
                  <br>
  
                  <div class="row mb-3">
  
                    <div class="col">
  
                      <select class="form-select" id="actoresSelect" aria-label="Default select example"
                        (change)="changeForAgregarTrabajadorBtn()">
                        <option [value]="500">Seleccionar trabajador</option>
  
                        <option [value]="501">Nuevo trabajador</option>
  
                        <option *ngFor="let trabajador of trabajadores; let id = index" value="{{id}}">
                          {{trabajador.nombre}}
                          {{trabajador.apellidoPaterno}} {{trabajador.apellidoMaterno}}</option>
                      </select>
                    </div>
                  </div>
  
                  <div class="row mb-3" id="direccion">
                    <div class="row mb-3">
                      <div class="col">
                        <label>Nombre</label>
                        <input type="text" id="wNombre" class="form-control" placeholder="Nombre"
                          formControlName="nomActor1">
                        <div class="error-msg"
                          *ngIf="((this.form.get('nomActor1').invalid && buttonBool) || (this.form.get('nomActor1').invalid && this.form.get('nomActor1').touched))">
                          Apellido paterno requerido
                        </div>
                      </div>
                      <div class="col">
                        <label>Apellido paterno</label>
                        <input type="text" id="wApellidoPaterno" class="form-control" placeholder="Apellido paterno"
                          formControlName="apPaAct1">
                        <div class="error-msg"
                          *ngIf="((this.form.get('apPaAct1').invalid && buttonBool) || (this.form.get('apPaAct1').invalid && this.form.get('apPaAct1').touched))">
                          Apellido paterno requerido
                        </div>
                      </div>
                      <div class="col">
                        <label>Apellido materno</label>
                        <input type="text" id="wApellidoMaterno" class="form-control" placeholder="Apellido materno"
                          formControlName="apMaAct1">
                        <div class="error-msg"
                          *ngIf="((this.form.get('apMaAct1').invalid && buttonBool) || (this.form.get('apMaAct1').invalid && this.form.get('apMaAct1').touched))">
                          Apellido materno o no especifica
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
  
                      <div class="col">
  
                        <div class="col">
                          <label for="sexoActor">Sexo</label>
                          <select id="genero" class="form-control" name="ent" formControlName="genero1">
                            <option disabled selected>Selecionar una opción</option>
                            <option value="1">MUJER</option>
                            <option value="0">HOMBRE</option>
                          </select>
                          <div class="error-msg"
                            *ngIf="((this.form.get('genero1').invalid && buttonBool) || (this.form.get('genero1').invalid && this.form.get('genero1').touched))">
                            Año de nacimiento o no especifica
                          </div>
                        </div>
                        <div class="col">
                          <label for="anNacimiento5">Año de nacimiento</label>
                          <input type="text" id="anioN" class="form-control" onKeyUp="if(this.value>2020){this.value='';}"
                            placeholder="Año de nacimiento" formControlName="anioN">
                          <div class="error-msg"
                            *ngIf="((this.form.get('anioN').invalid && buttonBool) || (this.form.get('anioN').invalid && this.form.get('anioN').touched))">
                            Año de nacimiento o no especifica
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-lg-4">
                          <label for="alcaldia5">Alcaldía</label>
                          <select class="form-control" id="alcaldia" formControlName="alcaldia1">
  
                            <option value="Álvaro Obregón">Álvaro Obregón</option>
                            <option value="Azcapotzalco">Azcapotzalco</option>
                            <option value="Benito Juárez">Benito Juárez</option>
                            <option value="Coyoacán">Coyoacán</option>
                            <option value="Cuajimalpa">Cuajimalpa</option>
                            <option value="Cuauhtémoc">Cuauhtémoc</option>
                            <option value="Gustavo A. Madero">Gustavo A. Madero</option>
                            <option value="Iztacalco">Iztacalco</option>
                            <option value="Iztapalapa">Iztapalapa</option>
                            <option value="Magdalena Contreras">Magdalena Contreras</option>
                            <option value="Miguel Hidalgo">Miguel Hidalgo</option>
                            <option value="Milpa Alta">Milpa Alta</option>
                            <option value="Tláhuac">Tláhuac</option>
                            <option value="Tlalpan">Tlalpan</option>
                            <option value="Venustiano Carranza">Venustiano Carranza</option>
                            <option value="Xochimilco">Xochimilco</option>
                          </select>
                          <div class="error-msg"
                            *ngIf="((this.form.get('alcaldia1').invalid && buttonBool) || (this.form.get('alcaldia1').invalid && this.form.get('alcaldia1').touched))">
                            Es necesario seleccionar una alcaldía
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                          <label for="colonia">Colonia</label>
                          <input type="text" id="colonia" class="form-control" placeholder="Colonia"
                            formControlName="col1">
                          <div class="error-msg"
                            *ngIf="((this.form.get('col1').invalid && buttonBool) || (this.form.get('col1').invalid && this.form.get('col1').touched))">
                            Colonia o no especifica
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                          <label for="cp5">Código Postal</label>
                          <input type="text" id="cp" class="form-control" onKeyUp="if(this.value>99999){this.value='';}"
                            placeholder="Código Postal" formControlName="cp1">
                          <div class="error-msg"
                            *ngIf="((this.form.get('cp1').invalid && buttonBool) || (this.form.get('cp1').invalid && this.form.get('cp1').touched))">
                            Código postal o no especifica
                          </div>
                        </div>
                        <div class="col">
                          <label for="categoria">Categoría</label>
                          <input type="text" id="categoria" class="form-control" placeholder="Categoría"
                            formControlName="categ">
                          <div class="error-msg"
                            *ngIf="((this.form.get('categ').invalid && buttonBool) || (this.form.get('categ').invalid && this.form.get('categ').touched))">
                            Es necesario seleccionar una categoría
                          </div>
                          <label for="fechaEntrada">Fecha de entrada</label>
                          <input class="form-control" type="date" id="fechaEntrada" onKeyDown="return false"
                            min="1900-01-01" [max]="today" formControlName="fechaEn">
                          <label for="fechaSalida">Fecha de salida</label>
                          <input class="form-control" type="date" id="fechaSalida" onKeyDown="return false"
                            [min]="this.form.get('fechaEn').value" [max]="today" formControlName="fechaSal">
                          <label for="personaDespide">Persona que despide</label>
                          <input type="text" id="personaDespide" class="form-control" placeholder="Nombre o No especifica"
                            formControlName="personaDes">
                          <div class="error-msg"
                            *ngIf="((this.form.get('personaDes').invalid && buttonBool) || (this.form.get('personaDes').invalid && this.form.get('personaDes').touched))">
                            Persona que despide o no especifica
                          </div>
                          <label for="accion">Acción</label>
                          <select id="accion" class="form-control" formControlName="acc">
                            <option value="Reinstalación">Reinstalación</option>
                            <option value="Indemnización Constitucional">Indemnización Constitucional</option>
                            <option value="Reducción de salario">Reducción de salario</option>
                            <option value="Pago de prestaciones">Pago de prestaciones</option>
                            <option value="Declaración de beneficiario">Declaración de beneficiario</option>
                          </select>
                          <div class="error-msg"
                            *ngIf="((this.form.get('acc').invalid && buttonBool) || (this.form.get('acc').invalid && this.form.get('acc').touched))">
                            La acción es requerida
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <label for="causa">Causa</label>
                              <select id="causa" class="form-control" formControlName="causa"
                                (change)="changeForOtraRazonEspecifica()">
  
                                <option value="1">Sin previo aviso</option>
                                <option value="2">Reducción de salario</option>
                                <option value="3">No recibir salario o fecha esperada</option>
                                <option value="4">Reducción o cambio de horario</option>
                                <option value="5">Otro</option>
                              </select>
                              <div class="error-msg"
                                *ngIf="((this.form.get('causa').invalid && buttonBool) || (this.form.get('causa').invalid && this.form.get('causa').touched))">
                                La causa es requerida
                              </div>
  
  
                            </div>
                            <div class="col-md-6 col-lg-6">
                              <label for="causaEspecifica">Especifique</label>
                              <input type="text" id="causaEspecifica" class="form-control"
                                placeholder="Especificar la causa">
                              <div class="error-msg" *ngIf="!boolCausa">
                                Es necesario que indique la causa específica
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <label for="sueldoBase">Sueldo base</label>
                              <input type="number" id="sueldoBase" class="form-control" placeholder="Si se encuentra"
                                onKeyUp="if(this.value<=0){this.value='';}" formControlName="sueldoBas">
                              <div class="error-msg"
                                *ngIf="((this.form.get('sueldoBas').invalid && buttonBool) || (this.form.get('sueldoBas').invalid && this.form.get('sueldoBas').touched))">
                                El sueldo base es requerido
                              </div>
                            </div>
  
                            <div class="col-md-6 col-lg-6">
                              <label for="periodoSueldo">Periodicidad del sueldo</label>
                              <select id="periodoSueldo" class="form-control" formControlName="periodoS">
  
                                <option value="0">Diario</option>
                                <option value="1">Mensual</option>
                                <option value="2">Quincenal</option>
                                <option value="3">Semanal</option>
                                <option value="4">No especifíca</option>
                              </select>
  
  
                            </div>
                          </div>
  
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <label for="sueldoEst">Sueldo estadístico</label>
                              <input type="number" id="sueldoEst" class="form-control"
                                onKeyUp="if(this.value<=0){this.value='';}" placeholder="No puede estar vacío"
                                formControlName="sueldoEst">
                              <div class="error-msg"
                                *ngIf="((this.form.get('sueldoEst').invalid && buttonBool) || (this.form.get('sueldoEst').invalid && this.form.get('sueldoEst').touched))">
                                El sueldo estadístico es requerido
                              </div>
  
                            </div>
                            <div class="col-md-6 col-lg-6">
                              <label for="periodoSueldoEst">Periodicidad del sueldo estadístico</label>
                              <select id="periodoSueldoEst" class="form-control" formControlName="periodoSE">
                                <option value="0">Diario</option>
                                <option value="1">Mensual</option>
                                <option value="2">Quincenal</option>
                                <option value="3">Semanal</option>
                                <option value="4">No especifíca</option>
                              </select>
                              <div class="error-msg"
                                *ngIf="((this.form.get('periodoSE').invalid && buttonBool) || (this.form.get('periodoSE').invalid && this.form.get('periodoSE').touched))">
                                El tipo de jornada es requerida
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <label for="tipoJornada">Tipo de jornada</label>
                              <select id="tipoJornada" class="form-control" formControlName="tipoJ">
                                <option value="1">Diurna</option>
                                <option value="2">Nocturna</option>
                                <option value="3">Mixta</option>
                                <option value="4">24x24</option>
                              </select>
                              <div class="error-msg"
                                *ngIf="((this.form.get('tipoJ').invalid && buttonBool) || (this.form.get('tipoJ').invalid && this.form.get('tipoJ').touched))">
                                El tipo de jornada es requerida
                              </div>
  
                            </div>
                            <div class="col-md-6 col-lg-6">
                              <label for="horasLaboradas">Horas laboradas por semana</label>
                              <input type="text" id="horasLaboradas" class="form-control" placeholder="Si se encuentra"
                                formControlName="numHorasL">
                              <div class="error-msg"
                                *ngIf="((this.form.get('numHorasL').invalid && buttonBool) || (this.form.get('numHorasL').invalid && this.form.get('numHorasL').touched))">
                                Los otros montos son requeridos, solo números, 0 si no hay.
                              </div>
                            </div>
  
                          </div>
                          <br>
                          <label for="horasLaboradas"> <b>Marcar los siguientes puntos en caso de ser
                              necesario</b></label>
  
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="porProyecto">Trabaja por proyecto</label>
                                <input type="checkbox" class="form-check-input" id="porProyecto"
                                  style="margin-left: 10px;">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="trabConfianza">Trabajador de confianza</label>
                                <input type="checkbox" class="form-check-input" id="trabConfianza"
                                  style="margin-left: 10px;">
                              </div>
                            </div>
                          </div>
                          <br>
                          <h3>Información de las prestaciones</h3>
  
                          <br>
                          <label for="horasLaboradas"><b>Marcar los siguientes puntos en caso de ser necesario</b></label>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="porProyecto">Reinstalación</label>
                                <input type="checkbox" class="form-check-input" id="reinstalacion"
                                  style="margin-left: 10px;">
                              </div>
                            </div>
                          </div>
  
                          <div class="row">
                            <div class="col">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="porProyecto">Indemnización constitucional</label>
                                <input type="checkbox" class="form-check-input" id="indemnizacionConst"
                                  style="margin-left: 10px;"  (change)="changeForMonto()">
                              </div>
                            </div>
                          </div>
  
                          <div class="row" id="montoIndemn">
                            <div class="col">
                              <label for="montoIndemn">Monto indemnización</label>
                              <input type="number" id="montoIndemnA" onKeyUp="if(this.value<=0){this.value='';}" min=0
                                class="form-control" placeholder="Monto">
                              <div class="error-msg" *ngIf="!boolIndemn">
                                Es necesario que indique el monto
                              </div>
                            </div>
  
                          </div>
  
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="porProyecto">Salarios caídos</label>
                                <input type="checkbox" class="form-check-input" id="salariosCaidos"
                                  style="margin-left: 10px;" (change)="changeForMonto()">
                              </div>
                            </div>
                          </div>
                          <div class="row" id="montoSalariosCaidos">
                            <div class="col">
                              <label for="montoSal">Monto de salarios caídos</label>
                              <input type="number" id="montoSalariosCaidosA" onKeyUp="if(this.value<=0){this.value='';}"
                                min=0 class="form-control" placeholder="Monto">
                              <div class="error-msg" *ngIf="!boolMontoSalariosCaidos">
                                Es necesario que indique el monto
                              </div>
                            </div>
  
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="porProyecto">Prima de antigüedad</label>
                                <input type="checkbox" class="form-check-input" id="primaAntiguedad"
                                  style="margin-left: 10px;" (change)="changeForMonto()">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col" id="montoPrimaAntiguedad">
                              <label for="montoIndemn">Monto de prima de antigüedad</label>
                              <input type="number" id="montoPrimaAntiguedadA" onKeyUp="if(this.value<=0){this.value='';}"
                                min=0 class="form-control" placeholder="Monto">
                              <div class="error-msg" *ngIf="!boolMontoPrimaAntiguedad">
                                Es necesario que indique el monto
                              </div>
                            </div>
  
                          </div>
  
                          <br>
                          <div class="row">
                            <div class="col">
                              <br>
                              <label for="vacaciones">Vacaciones</label>
                              <select class="form-control" id="vacacionesRaz" (change)="changeForVacaciones()">
                                <option value="1">No especifica</option>
                                <option value="0">Especifica</option>
                                <option value="2">No aplica</option>
                                <option value="3">No pide vacaciones</option>
                              </select>
  
  
                            </div>
                          </div>
                          <div id="vacaciones">
                            <div class="row">
                              <div class="col-md-6 col-lg-6">
                                <label for="fechaInicialVac">Fecha inicial vacaciones</label>
                                <div class="form-group ">
                                  <input class="form-control" type="date" id="fechaInicialVac" onKeyDown="return false"
                                    min="1920-01-01" [max]="1920-01-01">
                                </div>
                                <div class="error-msg" *ngIf="!boolFechaInicialVac">
                                  Es necesario que indique la fecha Inicial
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-6">
                                <label for="fechaFinalVac">Fecha final vacaciones</label>
                                <div class="form-group ">
                                  <input class="form-control" type="date" id="fechaFinalVac" onKeyDown="return false"
                                    [max]="today" [min]="minDateVac" required>
  
                                </div>
                                <div class="error-msg" *ngIf="!boolfechaFinalVac">
                                  Es necesario que indique la fecha final
                                </div>
                              </div>
                            </div>
  
                            <div class="row">
                              <div class="col-md-8 col-lg-8">
                                <label for="razonPerVac"> Razón anual de días de período vacacional</label>
                                <input type="number" onKeyUp="if(this.value<=0){this.value='';}" id="razonPerVac"
                                  class="form-control" placeholder="Razón">
                              </div>
                              <div class="error-msg" *ngIf="!boolRazonVac">
                                Es necesario que indique la razón
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <div class="row">
                                  <div class="col-md-6 col-lg-6">
                                    <label class="form-check-label" for="primaVacacional">Prima vacacional</label>
                                  </div>
                                  <div class="col-md-1 col-lg-1">
                                    <input type="checkbox" class="form-check-input" id="primaVacacional"
                                      style="margin-left: 10px;" (change)="changeForPrimaVacaciones()">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="vacaciones2">
                            <div class="row">
                              <div class="col">
                                <label for="ptjVac">Porcentaje prima vacacional</label>
                                <input type="number" id="porcentajePrimaVac" onKeyUp="if(this.value<=0){this.value='';}"
                                  min=0 class="form-control" placeholder="Monto">
                              </div>
                              <div class="error-msg" *ngIf="!boolPorcentajePrimaVac">
                                Es necesario que indique el procentaje
                              </div>
                              <div class="col"><label for="ptjVac">Monto prima vacacional</label>
                                <input type="number" id="montoPrimaVac" onKeyUp="if(this.value<=0){this.value='';}" min=0
                                  class="form-control" placeholder="Monto">
                              </div>
                              <div class="error-msg" *ngIf="!boolMontoPrimaVac">
                                Es necesario que indique el monto
                              </div>
  
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="col">
                              <br>
                              <label for="vacaciones">Aguinaldo</label>
                              <select class="form-control" id="aguinaldoS" (change)="changeForAguinaldo()">
                                <option value="1">No especifica</option>
                                <option value="0">Especifica</option>
  
                                <option value="2">No aplica</option>
                                <option value="3">No pide aguinaldo</option>
                              </select>
  
  
                            </div>
                          </div>
                          <div id="aguinaldo">
                            <div class="row">
                              <div class="col-md-6 col-lg-6">
                                <label for="fechaInicialVac">Fecha inicial aguinaldo</label>
                                <div class="form-group ">
                                  <input class="form-control" type="date" id="fechaInicialAguinaldo"
                                    onKeyDown="return false" min="1920-01-01" [max]="1920-01-01">
                                </div>
                                <div class="error-msg" *ngIf="!boolfechaIniAguinaldo">
                                  Es necesario que indique la fecha Inicial
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-6">
                                <label for="fechaFinalAguinaldo">Fecha final aguinaldo</label>
                                <div class="form-group ">
                                  <input class="form-control" type="date" id="fechaFinalAguinaldo"
                                    onKeyDown="return false" [max]="today" [min]="minDateVac" required>
  
                                </div>
                                <div class="error-msg" *ngIf="!boolfechaFinAguinaldo">
                                  Es necesario que indique la fecha final
                                </div>
                              </div>
                            </div>
  
                            <div class="row">
                              <div class="col-md-8 col-lg-8">
                                <label for="razonPerVac"> Razón anual de días por año de aguinaldo</label>
                                <input type="number" onKeyUp="if(this.value<=0){this.value='';}" id="razonAguinaldo"
                                  class="form-control" placeholder="Razón">
                              </div>
                              <div class="error-msg" *ngIf="!boolRazonAguinaldo">
                                Es necesario que indique la razón anual de días
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <br>
                              <label for="vacaciones">Horas extra</label>
                              <select class="form-control" id="horasExtrasS" (change)="changeForHorasExtras()">
                                <option value="1">No especifica</option>
                                <option value="0">Especifica</option>
                                <option value="2">No aplica</option>
                                <option value="3">No pide horas extras</option>
                              </select>
  
  
                            </div>
                          </div>
                          <div id="horasExtras">
                            <div class="row">
                              <div class="col-md-6 col-lg-6">
                                <label for="fechaInicialVac">Fecha inicial horas extras</label>
                                <div class="form-group ">
                                  <input class="form-control" type="date" id="fechaInicialHorasExtras"
                                    onKeyDown="return false" min="1920-01-01" [max]="1920-01-01">
                                </div>
                                <div class="error-msg" *ngIf="!boolfechaIniHorasExtras">
                                  Es necesario que indique la fecha Inicial
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-6">
                                <label for="fechaFinalVac">Fecha final horas extras</label>
                                <div class="form-group ">
                                  <input class="form-control" type="date" id="fechaFinalHorasExtras"
                                    onKeyDown="return false" [max]="today" [min]="minDateVac" required>
  
                                </div>
                                <div class="error-msg" *ngIf="!boolfechaFinHorasExtras">
                                  Es necesario que indique la fecha final
                                </div>
                              </div>
                            </div>
  
  
                            <div class="row">
                              <div class="col-md-8 col-lg-8">
                                <label for="razonPerVac"> Número de horas extras</label>
                                <input type="text" id="numHorasExtras" class="form-control" placeholder="Número de horas">
                              </div>
                              <div class="error-msg" *ngIf="!boolNumHorasExtras">
                                Es necesario que indique el número de horas extras
                              </div>
                            </div>
                          </div>
                          <br>
                          <br>
                          <label for="puntos"><b>Marcar los siguientes puntos en caso de ser necesario</b></label>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="porProyecto">Indemnización 20 días</label>
                                <input type="checkbox" class="form-check-input" id="indemnizacion20"
                                  style="margin-left: 10px;" (change)="changeForMonto()">
                              </div>
                            </div>
                          </div>
  
                          <div class="row" id="montoIndemn20">
                            <div class="col">
                              <label for="montoIndemn">Monto indemnización 20 días</label>
                              <input type="number" id="montoIndemn20A" onKeyUp="if(this.value<=0){this.value='';}" min=0
                                class="form-control" placeholder="Monto">
                              <div class="error-msg" *ngIf="!boolIndemn20">
                                Es necesario que indique el monto
                              </div>
                            </div>
  
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="cuotas">Cuotas SAR/IMSS/INFONAVIT
                                </label>
                                <input type="checkbox" class="form-check-input" id="cuotas" style="margin-left: 10px;">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 col-lg-6">
                              <div class="form-group form-check" style="padding-left: 0px !important;">
                                <label class="form-check-label" for="cuotas">Nulidad
                                </label>
                                <input type="checkbox" class="form-check-input" id="nulidad" style="margin-left: 10px;">
                              </div>
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="col-md-8 col-lg-8">
                              <label for="razonPerVac"> Salarios devengados</label>
                              <input type="number" onKeyUp="if(this.value<=0){this.value='';}" id="salariosDeven"
                                class="form-control" placeholder="Salarios devengados" formControlName="salariosDeven">
                            </div>
                            <div class="error-msg"
                              *ngIf="((this.form.get('salariosDeven').invalid && buttonBool) || (this.form.get('salariosDeven').invalid && this.form.get('salariosDeven').touched))">
                              Los otros montos son requeridos, solo números, 0 si no hay.
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-8 col-lg-8">
                              <label for="razonPerVac"> Otros montos cuantificados</label>
                              <input type="number" onKeyUp="if(this.value<=0){this.value='';}"
                                id="otrosMontosCuantificados" class="form-control" placeholder="Otros montos"
                                formControlName="otrosMontosCuantificados">
                            </div>
                            <div class="error-msg"
                              *ngIf="((this.form.get('otrosMontosCuantificados').invalid && buttonBool) || (this.form.get('otrosMontosCuantificados').invalid && this.form.get('otrosMontosCuantificados').touched))">
                              Los otros montos son requeridos, solo números, 0 si no hay.
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="col">
                              <button class="btn btn-outline-info btn-lg" id="agregarActor" (click)="agregarTrabajador()"
                                [disabled]="agregarAct"><i class="bi bi-person-plus-fill"></i> Agregar trabajador
                              </button>
  
                            </div>
                            <div class="col">
                              <button class="btn btn-outline-info btn-lg" id="actualizarActor"
                                (click)="actualizarTrabajador()" [disabled]="actualizarAct"><i
                                  class="bi bi-person-plus-fill"></i> Actualizar trabajador
                              </button>
                            </div>
                          </div>
  
  
  
  
  
  
  
  
                        </div>
  
                      </div>
  
                    </div>
  
                  </div>
                </form>
  
              </div>
              <div>
                <button class="btn-seccion" (click)="observacionesDisplay()"><span><label
                      class="h4 font-weight-normal">Observaciones</label><span class="rounded float-end"
                      *ngIf="seccionObservaciones"><i class="bi bi-chevron-double-up"></i></span><span
                      class="rounded float-end" *ngIf="!seccionObservaciones"><i
                        class="bi bi-chevron-double-down"></i></span></span></button>
                <hr>
                <div id="seccionObservaciones">
                  <h3>Observaciones de la demanda</h3>
                  <label><b>Explicar brevemente las observaciones de la demanda</b> </label>
                  <textarea class="form-control" id="observaciones" rows="3" ></textarea>
                  
                  <br>
                  <button type="button" class="btn btn-greenL" (click)="terminarCaptura()">Terminar demanda</button>
                  <br>
                  <br>
                </div>
              </div>
  
  
  
  
  
            </div>
  
            <!-- Lado derecho -->
            <div class="col">
              <div class="row m-3">
                <div class="col">
                  
                </div>
              </div>
              <div>
                <object class="flotante" [data]="pdfSource" type="application/pdf" height="3000">
                  <embed [src]="pdfSource" type="application/pdf">
                </object>
              </div>
            </div>
  
          </div>
  
  
        </div>
  
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="goMenu()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{mensaje}}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="goMenu()"data-bs-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="cambioActor" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Aviso</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{mensaje}}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div>