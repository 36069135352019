import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


var baseUrl;

@Injectable({
  providedIn: 'root'
})

export class ControlesService{
    constructor(private http: HttpClient) {
        baseUrl='http://localhost:8080/api/';
    }
    
    getAll(idExpediente?:string, idCapturista?:string, idFiscalizador?:string, estatus?:string) {
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        let params = new HttpParams();
        if(idExpediente!=undefined && idExpediente !=''){
            params = params.append('idExpediente',idExpediente+"");
        }
        if(idCapturista!=undefined && idCapturista !=''){
            params = params.append('capturista',idCapturista+"");
        }
        if(idFiscalizador!=undefined && idFiscalizador !=''){
            params = params.append('idFiscalizador',idFiscalizador+"");
        }
        if(estatus!=undefined && estatus !=''){
            params = params.append('estatus',estatus+"");
        }

        return new Promise(resolve=>{
            this.http.get(baseUrl+'controles', {'headers':headers, 'params':params}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    createCon(data) {
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.post(baseUrl+'controles', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    updateCon(data){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.put(baseUrl+'controles', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
        
    }

    asignarExpediente(data){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.post(baseUrl+'asignarExp', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    sortearExpedientes(data){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.post(baseUrl+'sortearExp', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    eliminarControl(idExpediente?:string){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        let params = new HttpParams();
        if(idExpediente!=undefined && idExpediente !=''){
            params = params.append('idExpediente',idExpediente+"");
        }
        return new Promise(resolve=>{
            this.http.delete(baseUrl+'controles', {'headers':headers,'params':params}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    generarReporte(){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        return new Promise(resolve=>{
            this.http.get(baseUrl+'reportes', {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }
    
}