import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,Validators, FormControl } from '@angular/forms'; //Bibliotecas usadas para validacion
import { Router } from  "@angular/router";
import { LoginService } from '../services/login.service';
import { Modal } from 'bootstrap';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  incorrecto=false;
  formModal: any;
  buttonBool: boolean;

  constructor(private formBuilder: FormBuilder, private apiLogin: LoginService, private router:Router) { }

  ngOnInit(): void {
    this.form=this.formBuilder.group({
      emailInput: [null,Validators.compose([Validators.required, Validators.email])], //Campo de nombre, restriciones: requerido y solo letras
      psswd:[null, Validators.required],
      
    });
  }
  submit(){
    var user=((document.getElementById("inputUser") as HTMLInputElement).value);
    var psswd=((document.getElementById("inputPassword") as HTMLInputElement).value);
    var myModal = new Modal(document.getElementById('exampleModal'));
    this.buttonBool=true;
   
    
    this.apiLogin.login(user,psswd).then(result=>{
      if (result["status"]>=400){
        console.log("Credenciales incorrectas");
        myModal.toggle();
        return null;
      }
      else{
        return this.apiLogin.getGroup();
      }
    }).then(result=>{
      if (result==null){
        console.log('ERROR: Credenciales incorrectas');
        myModal.toggle();
        //Mostrar algo de error
      }
      else if (result['grupo']=='Admin'){
        console.log('admin');
        this.router.navigate(['/menuAdmin/']);
      }
      else if (result['grupo']=='Fiscalizador'){
        console.log('fiscalizador');
        this.router.navigate(['/menuFisc/']);
      }
      else if (result['grupo']=='Capturista'){
        console.log('capturista');
        this.router.navigate(['/menu/']);
      }
      else{
        console.log('ERROR: Usuario no tiene asignado grupo');
      }
    });
  }
    

}
