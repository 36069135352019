<div class="row">
    <div class="col">
        <div class="card m-0 p-0">
            <div class="card-body">
                <form [formGroup]="">
                    <div class="row">
                        <div class="col">
                            <h1 class="h4 mb-3 font-weight-normal">Reportes</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button id="btnContinuar" class="btn btn-greenL" type="button"
                                (click)="reporteCSV()">Reporte CSV</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>