import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


var baseUrl;

@Injectable({
  providedIn: 'root'
})

export class CapturistasService{
    constructor(private http: HttpClient) {
        baseUrl='http://localhost:8080/api/';
    }
    
    getAll(correo?:string) {
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        let params = new HttpParams();
        if(correo!=undefined && correo !=''){
        params = params.append('correo',correo+"");
        }
        return new Promise(resolve=>{
            this.http.get(baseUrl+'capturistas', {'headers':headers, 'params':params}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    createCap(data) {
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.post(baseUrl+'capturistas', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    createUser(data){
        //groups: 1-Capturista, 2-Fiscalizador, 3-Admin
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.post(baseUrl+'crearUsuario', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    updateCap(data){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.put(baseUrl+'capturistas', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
        
    }

    infoColaborador(correo){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        let params = new HttpParams();
        if(correo!=undefined && correo !=''){
            params = params.append('correo',correo+"");
        }
        else{
            return null;
        }
        return new Promise(resolve=>{
            this.http.get(baseUrl+'infoColaboradores', {'headers':headers, 'params':params}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        }); 
    }
    
}