
    <div class="row">
        <div class="col">
            <div class="card m-0 p-0">
                <div class="card-body">
                    <form [formGroup]="">
                        <div class="row">
                            <div class="col">
                                <h1 class="h4 mb-3 font-weight-normal">Asignar fiscalizador</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label for="fiscAsig">Fiscalizador</label>
                                <select class="form-control" id="fiscAsig"
                                    [disabled]="!banderaLlenarFiscalizadores">
                                </select>
                            </div>
                            <!--
                            <div class="col" id="sp">
                                <div class="spinner-border spinner-border-sm" role="status"
                                    *ngIf=!banderaLlenarFiscalizadores></div>
                            </div>
                            -->
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label for="captAsig">Capturista</label>
                                <select class="form-control" id="captAsig"
                                    [disabled]="!banderaLlenarCapturistasSinFiscalizador">
                                </select>
                            </div>
                            <!--
                            <div class="col" id="sp">
                                <div class="spinner-border spinner-border-sm" role="status"
                                    *ngIf=!banderaLlenarCapturistasSinFiscalizador>
                                </div>
                            </div>
                            -->
                        </div>
                        <div class="row my-3">
                            <div class="col">
                                <button class="btn btn-greenL" type="button"
                                    (click)="asignacionCaptFis()">Guardar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card m-0 p-0">
                <div class="card-body">
                    <form [formGroup]="">
                        <div class="row">
                            <div class="col">
                                <h1 class="h4 mb-3 font-weight-normal">Desasociar fiscalizador</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label for="fiscDes">Fiscalizador</label>
                                <select class="form-control" id="fiscDes"
                                    [disabled]="!banderaLlenarFiscalizadores"
                                    (change)="cargarCaptXFisc()">
                                </select>
                            </div>
                            <!--
                            <div class="col" id="sp">
                                <div class="spinner-border spinner-border-sm" role="status"
                                    *ngIf=!banderaLlenarFiscalizadores></div>
                            </div>
                            -->
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label for="captDes">Capturista</label>
                                <select class="form-control" id="captDes"
                                    [disabled]="!banderaColabsXFis">
                                </select>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col">
                                <button class="btn btn-greenL" type="button"
                                    (click)="desasociarFisc()">Guardar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
