import { Component, OnInit } from '@angular/core';
import {LigasService} from '../../services/ligas.service';
import { ControlesService } from 'src/app/services/controles.service';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-gestion-expedientes',
  templateUrl: './gestion-expedientes.component.html',
  styleUrls: ['./gestion-expedientes.component.css']
})
export class GestionExpedientesComponent implements OnInit {
  traerInfoBandera: boolean=false;
  idExpedienteInfo: any;
  capturistaInfo: any;
  estatusInfo: any;
  fechaInfo: any;
  fiscalizadorInfo: any;
  fiscalizadoInfo: string;

  constructor(private apiLigas: LigasService, private apiControl: ControlesService) { }
  myModal ;
  mensaje="";

  ngOnInit(): void {
    this.myModal= new Modal(document.getElementById('exampleModal'));
  }

  borrar(){}

  expTracker(){}

  subirBloqueLigas(array){
    var data = {
      "exps":array
    }
    /**
     * {
     *    "exps": [
     *      "source" : "Google Drive" <- Por lo mientras se quedará así
            "fechaSubida" : "fecha del momento en el que se sube todo, duh"
            "liga" : "https://drive.google.com/file/d/11_YMEFISxwR3bC1s0HjXCL6VPuf7xwUM0ddnfKbYi1A/preview" <- Ejemplo
            "idExpediente" : "18_1336_2019" <- Ejemplo
            "prioridad" : Entero del 0 al 3, conforme aumenta la prioridad es menor
     *    ]
     * }
     */
    this.apiLigas.subirLigas(data).then(result =>{
      console.log(result);
      this.mensaje="Se subieron las ligas exitosamente.";
      this.myModal.toggle();
      if (result["status"]>=400){
        console.log("Error al subir ligas");
        this.mensaje="Error al subir ligas, por favor revisa el documento.";
      this.myModal.toggle();
      }
    });
  }

  desasignarExp(){
    var junta= (document.getElementById("juntaDes") as HTMLInputElement).value;
    var exp= (document.getElementById("expDes") as HTMLInputElement).value;
    var anio= (document.getElementById("anDes") as HTMLInputElement).value;
    if(junta!=""&& exp!=""&&anio!=""){
      var idExp= junta+"_"+exp+"_"+anio;
      this.desasignarExpediente(idExp);
      this.mensaje="Se desasignó el expediente correctamente.";
      this.myModal.toggle();
    }else{
      this.mensaje="Datos incompletos.";
      this.myModal.toggle();
    }
  }

  desasignarExpediente(idExpediente){
    this.apiControl.eliminarControl(idExpediente).then(result=>{
      console.log(result);
      if (result["status"]>=400){
        console.log("Error al borrar el control");
        return null;
      }
      else{
        var dataLigas={
          "idExpediente":idExpediente,
          "prioridad":1
        }
        return this.apiLigas.updateDem(dataLigas);
      }
    }).then(result=>{
      console.log(result);
      if (result["status"]>=400){
        console.log("Error al actualizar liga");
      }
    });
  }

  expPrioridad(){
    var junta= (document.getElementById("juntaP") as HTMLInputElement).value;
    var exp= (document.getElementById("expP") as HTMLInputElement).value;
    var anio= (document.getElementById("anP") as HTMLInputElement).value;
    var prioridad=(document.getElementById("prioridad") as HTMLInputElement).value;
    if(junta!=""&& exp!=""&&anio!=""){
      var idExp= junta+"_"+exp+"_"+anio;
      this.cambiarPrioridadExpediente(idExp,prioridad);
      this.mensaje="Se cambio la prioridad del expediente correctamente.";
      this.myModal.toggle();
    }else{
      this.mensaje="Datos incompletos.";
      this.myModal.toggle();
    }
  }

  cambiarPrioridadExpediente(idExpediente,prioridad){
    var dataLiga={
      "idExpediente":idExpediente,
      "prioridad":prioridad
    }
    this.apiLigas.updateDem(dataLiga).then(result=>{
      console.log(result);
      if (result["status"]>=400){
        console.log("Error al actualizar liga");
      }
    });
  }

  traerInfo(){
    var junta= (document.getElementById("juntaInf") as HTMLInputElement).value;
    var exp= (document.getElementById("expInf") as HTMLInputElement).value;
    var anio= (document.getElementById("anInf") as HTMLInputElement).value;
    if(junta!=""&& exp!=""&&anio!=""){
      var idExp= junta+"_"+exp+"_"+anio;
    this.infoExpediente(idExp);
    this.mensaje="Información lista para consultar.";
    this.myModal.toggle();
    this.traerInfoBandera=true;
    }else{
      this.mensaje="Datos incompletos.";
      this.myModal.toggle();
    }
  }

  infoExpediente(idExpediente){
    this.apiControl.getAll(idExpediente).then(result=>{
      console.log(result)
      var estatus;
      switch(result[0]["estatus"]){
        case "fis":
          estatus = "Fiscalizado";
          break;
        case "fin":
          estatus= "Finalizado";
          break;
        case "aC":
          estatus= "A corregir";
          break;
        case "eP":
          estatus= "En proceso";
          break;
        case "aF":
          estatus= "A fiscalizar";
          break;
        case "c":
          estatus= "Capturado";
          break;
      }
      var fisc= "Fiscalizado";
      if(!result[0]["fiscalizado"]){
        fisc= "No fiscalizado"
      }
      this.idExpedienteInfo=result[0]["idExpediente"];
      this.capturistaInfo=result[0]["capturista"];
      this.estatusInfo=estatus;
      this.fechaInfo=result[0]["fechaAuto"];
      this.fiscalizadorInfo=result[0]["fiscalizador"];
      this.fiscalizadoInfo=fisc;

      if (result["status"]>=400){
        console.log("Expediente no encontrado");
      }
    });
  }

  file:any;
  array:any;
  async fileChanged(e){
    this.file = e.target.files[0];
    const fileContent = await this.readFileContent(e.target.files[0]);
    console.log(fileContent);
    this.array = JSON.parse(fileContent)['exps'];
    console.log(this.array);
  }

  readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        if (!file) {
            resolve('');
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = reader.result.toString();
            resolve(text);
        };
        reader.readAsText(file);
    });
  }
  subirLigas(){
    this.subirBloqueLigas(this.array); //?? Checcar que jale
  }
}
