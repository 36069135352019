import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


var baseUrl;

@Injectable({
  providedIn: 'root'
})

export class DemandasService{
    constructor(private http: HttpClient) {
        baseUrl='http://localhost:8080/api/';
    }
    
    getAll(idExpediente?:string) {
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        let params = new HttpParams();
        if(idExpediente!=undefined && idExpediente !=''){
        params = params.append('idExpediente',idExpediente+"");
        }
        return new Promise(resolve=>{
            this.http.get(baseUrl+'demandas', {'headers':headers, 'params':params}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    createDem(data) {
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.post(baseUrl+'demandas', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
    }

    updateDem(data){
        let headers = new HttpHeaders(); 
        headers=headers.append('Authorization', `Bearer ${JSON.parse(localStorage.getItem('userIni')).access_token}`);
        headers=headers.append('Accept', 'application/json');
        return new Promise(resolve=>{
            this.http.put(baseUrl+'demandas', data, {'headers':headers}).subscribe(
                data=>{
                    resolve(data);
                },
                error=>{
                    console.log(error);
                }
            )
        });
        
    }
    
}