<div class="row p-auto m-auto w-100 h-100">
    <div class="col-12 w-100 h-100">
        <div class="row m-3">
            <div class="col">
                <h1 class=" mb-3 text-center">Menú principal</h1>
            </div>
        </div>
        <div class="row ">
            <div class="col-2">
                <ul class="nav flex-column nav-pills nav-fill">
                    <li class="nav-item">
                        <button id="btnContinuar" class="btn-menu-admin btn" type="button"
                            (click)="gestionColaboradores()">Gestión de colaboradores</button>
                    </li>
                    
                    <!--
                    <li class="nav-item">
                        <button id="btnContinuar" class="btn-menu-admin btn" type="button"  (click)="urgentes()">Fiscalizaciones urgentes</button>
                    </li>
                    -->
                    <li class="nav-item">
                        <button id="btnContinuar" class="btn-menu-admin btn" type="button"
                            (click)="reportes()">Reportes</button>
                    </li>
                    <li class="nav-item">
                        <button id="btnContinuar" class="btn-menu-admin btn" type="button"
                            (click)="borrarExpediente()">Gestión expedientes</button>
                    </li>
                </ul>
            </div>
            <div class="col-10">
                <app-gestion-colaboradores *ngIf="panelColaboradores"></app-gestion-colaboradores>
                <app-asignar-fiscalizador *ngIf="panelFiscalizador"></app-asignar-fiscalizador>
                <app-reportes *ngIf="panelReportes"></app-reportes>
                <app-gestion-expedientes *ngIf="panelExpedientes"></app-gestion-expedientes>
            
            </div>
        </div>
    </div>
</div>