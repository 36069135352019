import { Component, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { ControlesService } from '../services/controles.service';
import { CapturistasService } from '../services/capturistas.service';
import { LoginService } from '../services/login.service';
import { ignoreElements } from 'rxjs/operators';
import { Modal } from 'bootstrap';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  
  constructor(private route: ActivatedRoute, private router: Router, private apiControl: ControlesService, private apiCapturista: CapturistasService,
    private apiLogin: LoginService) { }

  porCapturar:Array<String>=[];
  Capturados:Array<String>=[];
  porRevisar:Array<String>=[];
  Terminados:Array<String>=[];
  porCorregir:Array<String>=[];

  boolPorCorregir=true;
  boolFiscalizados=true;
  boolCapturados=true;
  boolTerminados=true;

  primeroACorregir:String;
  myModal ;
  mensaje="";

  ngOnInit(): void {
    this.myModal= new Modal(document.getElementById('exampleModal'));
    this.checkLogin().then(result=>{
      if (result==true){
        this.getExpedientesCapturista(JSON.parse(localStorage.getItem("userIni"))["usuario"]);// cambiar esto
      }
    });
  }

  checkLogin(){
    return new Promise((resolve) => {
      this.apiLogin.getGroup().then(result=>{
        if (result['grupo']!='Capturista'){
          this.router.navigate(['/login/']);
          resolve(false);
        }
        else{
          resolve(true);
        } 
      });
    });
  }

  muestraDiv(tipo){
    switch(tipo){
      case 1: 
        this.boolPorCorregir=false;
        this.boolFiscalizados=true;
        this.boolCapturados=true;
        this.boolTerminados=true;
        break;
      case 2:
        this.boolPorCorregir=true;
        this.boolFiscalizados=false;
        this.boolCapturados=true;
        this.boolTerminados=true;
        break;
      case 3:
        this.boolPorCorregir=true;
        this.boolFiscalizados=true;
        this.boolCapturados=false;
        this.boolTerminados=true;
        break;
      case 4:
        this.boolPorCorregir=true;
        this.boolFiscalizados=true;
        this.boolCapturados=true;
        this.boolTerminados=false;
        break;
    }

  }
  goCaptura(){
    this.router.navigate(['/captura/']);
  }
  corregir(){
    
    this.getExpedientesCapturista(JSON.parse(localStorage.getItem("userIni"))["usuario"]);
    if(this.porCorregir.length>0){
      var corregir= this.porCorregir[0];
      this.router.navigate(['/corregir/'+corregir]);

    }
    else{
      this.mensaje="No tienes expedientes a corregir."
      this.myModal.toggle();

    }
    
    
    
  }
  revisar(){
    
    if(this.porRevisar.length>0){
      var revisar = this.porRevisar[0];
      this.router.navigate(['/revisar/'+revisar]);

    }else{
      this.mensaje="No tienes expedientes a revisar."
      this.myModal.toggle();

    }
    
    
  }

  getCapturista(correo){
    return this.apiCapturista.getAll(correo);
  }

  getControl(idExpedienteediente,idCapturista){
    return this.apiControl.getAll(idExpedienteediente, idCapturista);
  }

  //Método para verificar conexión
  checkGroup(){
    return this.apiLogin.getGroup();
  }

  getExpedientesCapturista(idCapturista){
    this.getControl('',idCapturista).then((res:Array<JSON>)=>{
      var result = res;
      /**
        ('aC','aCorregir'),
        ('aF', 'aFiscalizar'),
        ('c', 'capturado'),
        ('fin', 'finalizado'),
        ('fis', 'fiscalizado')
      */
      console.log(result);

      for(var i=0; i<result.length; i++){
        if(result[i]['estatus']=='aC'){
          //apendear a lista de a Corregir
          
          this.porCorregir.push(result[i]['idExpediente']); 
        }
        if(result[i]['estatus']=='aF'){
          this.Capturados.push(result[i]['idExpediente']);
          //apendear a lista de a fiscalizar
          
          
        }
        if(result[i]['estatus']=='c'){
          //apendear a lista de capturados
          
          this.Capturados.push(result[i]['idExpediente']); 
        }
        if(result[i]['estatus']=='fin'){
          //apendear a lista de finalizados
          
          this.Terminados.push(result[i]['idExpediente']); 
        }
        if(result[i]['estatus']=='fis'){
          //apendear a lista de fiscalizados
          
          this.porRevisar.push(result[i]['idExpediente']); 
        }
      }
    });
  }

  asignarExpediente(usuario){
    this.apiControl.asignarExpediente({"username":usuario}).then(result=>{
      
    });
  }


}
